import React, { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import Arf from '../../assets/arf.svg';
import JSONData from '../../../content/popup.json';

import './index.scss';

export default function Popup({ open, onClose }) {
  const popupRef = useRef();

  const onClick = useCallback(e => {
    if (!popupRef.current.contains(e.target)) {
      onClose();
    }
  }, [onClose]);

  const onKeyup = useCallback(e => {
    if (e.which === 27) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (open) {
      document.body.classList.add('Popup--open');

      document.addEventListener('click', onClick);
      document.addEventListener('keyup', onKeyup);
    }

    return () => {
      document.body.classList.remove('Popup--open');

      document.removeEventListener('click', onClick);
      document.removeEventListener('keyup', onKeyup);
    };
  }, [open, onClick, onKeyup]);

  // createPortal is not supported on SSR, so this break when deploying to Netlify
  if (typeof window === 'undefined') return null;

  return createPortal((
    <CSSTransition
      classNames="popup"
      in={open}
      timeout={400}
    >
      <div className="Popup">
        <div className="Popup__close">
          <div className="Popup__exit" />
        </div>

        <div className="Popup__container" ref={popupRef}>
          <div className="Popup__img">
            <figure
              style={{
                backgroundImage: `url('${JSONData.image}')`,
              }}
            />
          </div>
          <div className="Popup__content">
            <h2 className="Popup__title">About Smol Pups</h2>
            <div className="Popup__text">
              <p>
                Ziti is our 6 lb, 1 year old Brussels Griffon. He inspired us to create Smol Pups because when we first
                got him we had trouble finding products made just for small dogs.
              </p>
              <p>
                Our friends with small dogs always ask where we get our products from, so we figured we would create
                this site to easily be able to share our favorite Ziti-approved products.
              </p>
            </div>
            <div>
              <a
                className="Popup__link"
                href="https://instagram.com/zitithegriffon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  Chase Ziti
                </span>
              </a>
            </div>
            <Arf className="Popup__svg" />
          </div>
        </div>
      </div>
    </CSSTransition>
  ), document.body);
}

Popup.defaultProps = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
