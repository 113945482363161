import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Popup from '../Popup';
import JSONData from '../../../content/menu.json';
import scrollTo from '../../utils/scrollTo';
import slugify from 'slugify';

import './index.scss';

export default function Header({ pathname }) {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: {eq: "categories"} }) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                title
                label
              }
            }
          }
        }
      }
    }
  `);

  const [scrollDirection, setScrollDirection] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const headerRef = useRef(null);
  const scrollRef = useRef(null);
  scrollRef.current = 0;

  const headerScroll = () => {
    const scrollTop = window.pageYOffset;

    if (Math.abs(scrollRef.current - scrollTop) <= 15) {
      return;
    }

    const headerHeight = headerRef.current.offsetHeight;
    // Multiply by 3 because the initial value of top is 3em
    const headerOffset = parseFloat(getComputedStyle(headerRef.current).fontSize) * 3;

    if (scrollTop <= headerOffset + headerHeight) {
      setScrollDirection(null);
    } else {
      if (scrollDirection !== 'Header--scrolling') {
        setScrollDirection('Header--scrolling');
      }
    }

    scrollRef.current = scrollTop;
  };

  useEffect(() => {
    window.addEventListener('scroll', headerScroll);
    window.addEventListener('resize', headerScroll);

    return () => {
      window.removeEventListener('scroll', headerScroll);
      window.removeEventListener('resize', headerScroll);
    };
  });

  const onAction = (title, id) => {
    trackCustomEvent({
      category: 'Header',
      action: 'click',
      label: title,
    });

    scrollTo(id);
  };

  const links =  JSONData.links.map(link => {
    for (let i = 0; i < allFile.edges.length; i++) {
      const frontmatter = allFile.edges[i].node.childMarkdownRemark.frontmatter;

      if (frontmatter.title === link) {
        const slugifiedTitle = slugify(frontmatter.title, { lower: true });

        return (
          <li key={slugifiedTitle}>
            {pathname === '/' ? (
              <span
                className="Header__link"
                onClick={() => onAction(frontmatter.label, `#${slugifiedTitle}`)}
                onKeyPress={() => onAction(frontmatter.label, `#${slugifiedTitle}`)}
                role="link"
                tabIndex="0"
              >
                {frontmatter.label}
              </span>
            ) : (
              <Link
                className="Header__link"
                activeClassName="Header__link--active"
                to={`/${slugifiedTitle}`}
              >
                {frontmatter.label}
              </Link>
            )}
          </li>
        );
      }
    }

    return null;
  });

  links.push((
    <li key="blog">
      <Link
        className="Header__link"
        activeClassName="Header__link--active"
        to="/blog"
      >
        Blog
      </Link>
    </li>
  ));

  links.push((
    <li key="about">
      <span
        className="Header__link"
        onClick={() => setPopupOpen(true)}
        onKeyPress={() => setPopupOpen(true)}
        role="link"
        tabIndex="0"
      >
        About
      </span>
    </li>
  ));

  const desktopClasses = classNames('Header', scrollDirection);

  return (
    <Fragment>
      <header className={desktopClasses} ref={headerRef}>
        <nav className="Header__nav">
          <ul className="Header__list">
            {links.slice(0, 3)}
          </ul>

          <div className="Header__title">
            <Link to="/">Smol Pups</Link>
          </div>

          <ul className="Header__list">
            {links.slice(3)}
          </ul>

          <div className="Header__mobile">
            <ul className="Header__list--mobile">
              {links}
            </ul>
          </div>
        </nav>
      </header>

      <Popup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
      />
    </Fragment>
  );
}

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
};
