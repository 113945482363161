import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';

import '../styles/fonts.scss';
import '../styles/app.scss';

export default function DefaultLayout({ location, children }) {
  return (
    <Fragment>
      <SEO />
      <div id="smol-pups">
        <Header pathname={location.pathname} />
        <main className="🐶💩">
          {children}
        </main>
        <Footer />
      </div>
    </Fragment>
  );
}

DefaultLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};
