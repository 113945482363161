import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Facebook from '../../assets/facebook.svg';
import Instagram from '../../assets/instagram.svg';
import Twitter from '../../assets/twitter.svg';

import './index.scss';

export default function Footer() {
  const onClick = name => {
    trackCustomEvent({
      category: 'Product',
      action: 'click',
      label: name,
    });
  };

  const link = (
    <a
      className="Footer__link"
      href="https://www.instagram.com/zitithegriffon/"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() =>onClick('Instagram')}
    >
      Ziti
    </a>
  );

  return (
    <footer className="Footer">
      <span>&copy; {new Date().getFullYear()}, Smol Pups</span>
      <div className="Footer__container">
        <span>Made by {link} in Seattle, WA</span>
        <ul className="Footer__list">
          <li>
            <a
              href="https://www.facebook.com/SmolPupsPets"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>onClick('Facebook')}
            >
              <Facebook className="Footer__svg" />
            </a>
          </li>
          <li>
            <a
              href="https://instagram.com/zitithegriffon"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>onClick('Instagram')}
            >
              <Instagram className="Footer__svg" />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/_smolpups"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>onClick('Twitter')}
            >
              <Twitter className="Footer__svg" />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
